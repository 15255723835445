// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: auto;
}

:root {
  --primary-color: #4ca30d;
  --secondary-color: #667085;
  --font-inter: "Inter", sans-serif;
  --ui-gray: #f9fafb;
}

.styled {
  display: "none !important";
}

body {
  font-family: var(--font-inter) !important;
  overflow-x: hidden !important;
}

.asterisk::after {
  content: "*";
  color: red;
}

@media (min-width: 750px) and (max-width: 1350px) {
  .gogeeper-hero-tab {
    width: 100% !important;
  }

  .gogeeper-swiper-section {
    flex-direction: column;
  }

  .gogeeper-swiper-section .swiper-first {
    width: 100% !important ;
    margin: 2em 0;
  }

  .gogeeper-swiper-section .swiper-second {
    width: 100% !important;
    border-radius: 0 0 1em 1em !important;
    background-position: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AACA;EACE,sBAAsB;EACtB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,wBAAwB;EACxB,0BAA0B;EAC1B,iCAAiC;EACjC,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,yCAAyC;EACzC,6BAA6B;AAC/B;;AAEA;EACE,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE;IACE,sBAAsB;EACxB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,uBAAuB;IACvB,aAAa;EACf;;EAEA;IACE,sBAAsB;IACtB,qCAAqC;IACrC,2BAA2B;EAC7B;AACF","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap\");\n* {\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n}\n\nhtml {\n  scroll-behavior: auto;\n}\n\n:root {\n  --primary-color: #4ca30d;\n  --secondary-color: #667085;\n  --font-inter: \"Inter\", sans-serif;\n  --ui-gray: #f9fafb;\n}\n\n.styled {\n  display: \"none !important\";\n}\n\nbody {\n  font-family: var(--font-inter) !important;\n  overflow-x: hidden !important;\n}\n\n.asterisk::after {\n  content: \"*\";\n  color: red;\n}\n\n@media (min-width: 750px) and (max-width: 1350px) {\n  .gogeeper-hero-tab {\n    width: 100% !important;\n  }\n\n  .gogeeper-swiper-section {\n    flex-direction: column;\n  }\n\n  .gogeeper-swiper-section .swiper-first {\n    width: 100% !important ;\n    margin: 2em 0;\n  }\n\n  .gogeeper-swiper-section .swiper-second {\n    width: 100% !important;\n    border-radius: 0 0 1em 1em !important;\n    background-position: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
