// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-tel-input .form-control {
  /* width: 100% !important;
    height: 3.5em !important; */
  width: 100% !important;
  height: 3.5em !important;
  min-width: 0px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  position: relative;
  border-radius: 8px;
  border: 1px solid #ededed;
  border-color: inherit;
  background: inherit;
  padding: 13px 16px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  outline: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE;+BAC6B;EAC7B,sBAAsB;EACtB,wBAAwB;EACxB,cAAc;EACd,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;EAClB,yBAAyB;EACzB,qBAAqB;EACrB,mBAAmB;EACnB,kBAAkB;EAClB,8CAA8C;EAC9C,wBAAwB;AAC1B","sourcesContent":[".react-tel-input .form-control {\n  /* width: 100% !important;\n    height: 3.5em !important; */\n  width: 100% !important;\n  height: 3.5em !important;\n  min-width: 0px;\n  outline: 2px solid transparent;\n  outline-offset: 2px;\n  position: relative;\n  border-radius: 8px;\n  border: 1px solid #ededed;\n  border-color: inherit;\n  background: inherit;\n  padding: 13px 16px;\n  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);\n  outline: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
